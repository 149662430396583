.bod{ 
        position: absolute;
        top: 235%;
        left: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3vh;
        color: aliceblue;
}
.bod h3{
   
    position: absolute;
    top: 230%;
    left: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size:  3vh;
    color: aliceblue;



}
.horzline{
    

    margin-top: 20%;
    width: 15px;
    height: 80px;
    color: aliceblue;
    padding: 1rem;





}
.horzline h3{
    
    margin-top: 17%;
    width: 15px;
    height: 80px;
    color: aliceblue;
    padding: 1rem;

}

.section2{
    position: absolute;
    top: 310%;
    right: 8%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 3vh;
    color: aliceblue;
}
.section2 h3{
    color: rgba(240, 248, 255, 0);
}

.section3{
    position: absolute;
    top: 380%;
    left: 8%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 3vh;
    color: aliceblue;



}

.section4{
    position: absolute;
    top: 315%;
    left: 8%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    
}

.section5{
    position: absolute;
    top: 380%;
    right: 8%;
}

.section5-image1{
    position: absolute;
    top: 60%;
    right: 75%;

}
