.ourwork{
    position: absolute;
    top: 170%;
    left: 18%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    font-size: 2.5vh;
    color: aliceblue;
}

.videop{
    margin-top: 27%;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    
}
.videop li{
    margin: 0 1rem;
    cursor: pointer;
    list-style: none;
}
.fade-in{
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.fade-in.appear{
    opacity: 1;
}

.fade-out{
    opacity: 0;
    transition: opacity 300ms ease-in;

}

.fade-out.appear{
    opacity: 1;
}


.video1{
    position: absolute;
    top: 190%;
    left: 13%;


}

.video2{
    position: absolute;
    top: 185%;
    left: 35%;


}

.video3{
    position: absolute;
    top: 190%;
    left: 68%;
}