.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.solgun{
  position: absolute;
  width: 100%;
  height:100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  color: rgb(255, 255, 255);
  font-size: x-large;
}

.solgun h2{
  color:rgba(23, 121, 206, 0);
}


/* .template{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */


.vl{
  border-left: 1px solid rgba(255, 255, 255, 0.14);
  height: 20%;
  /* height: ;40px */
  position: absolute;
  left: 50%;
  /* margin-left: 0px; */
  top: '50%';

}


::-webkit-scrollbar{
  width: 13px;
}

::-webkit-scrollbar-track{
  background-color: #282c34;
}

::-webkit-scrollbar-thumb{
  background-color: rgb(7, 40, 41);
  border-radius: 13px;

}